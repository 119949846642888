@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}

.body-mc {
  font-family: 'Tahoma', Verdana, Geneva, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  color: #fff;
  background: linear-gradient(180deg, #686768, #000000);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.container-mc {
  max-width: 940px !important;
  margin: auto;
}

.header-mc {
  background: url('/header-background-mc.jpg') repeat-y top center;
}

.main-mc {
  box-shadow: 0px 0px 16px 0px #0000001f;
  background-color: var(--tundora);
}

.search-link-mc {
  text-shadow: 0 4px 4px #000000;
}

.search-link-mc:hover {
  color: #fff;
  text-decoration: underline;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
}